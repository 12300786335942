<template>
    <ul class="progress">
        <li
            v-for="(item, index) in items"
            :key="item"
            class="progress__item"
            :class="{
                'progress__active': index === active
            }"
        >
            <span>{{ item }}</span>
        </li>
    </ul>
</template>

<script>
import './progress.scss'

export default {
    computed: {
        items() {
            return [
                'Анкета',
                'СМС',
                'Карта',
                this.$DICTIONARY.applicationProgressGet
            ]
        },
        active() {
            switch (this.$route.name) {
                case 'LoanContact':
                    return 0
                case 'LoanBefore':
                    return 1
                case 'LoanCard':
                    return 2
                default:
                    return -1
            }
        }
    }
}
</script>
